import React from "react"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import colors from "src/styles/colors"
import fonts from "src/styles/fonts"
import breakpoints from "src/styles/breakpoints"

const BlockContainer = styled.div`
  background: ${(props) => (props.bg === "Blue" ? colors.royalBlue : colors.lightGrey)};
  padding: ${(props) => (props.padded ? "3.3vmax 6vw" : "3.3vmax 17px")};
  display: flex;
  justify-content: center;


  @media (max-width: ${breakpoints.md}) {
    padding: 2vmax 17px;
  }
`

const Content = styled.div``

const Copy = styled.div`
  text-align: center;
  max-width: ${(props) => (props.small ? "700px" : "820px")};
  color: ${(props) => (props.bg === "Blue" ? colors.lightGrey : "inherit")};
  margin: auto;
  font-size: calc((1.2 - 1) * 1.2vw + 1rem);
  p {
    margin-bottom: 2rem;
    :last-of-type {
      margin-bottom: 0;
    }
  b {
    ${fonts.workSansBold};
    font-size: 1.15rem;
  }
  }

  a {
    color: ${colors.royalBlue};
    text-decoration-thickness: 1px;
    text-underline-offset: 0.2em;
  }

  h3 {
    max-width: 75%;
    margin: auto;
  }

  @media (min-width: ${breakpoints.lg}) {
    font-size: calc(1.2 * 1rem);
  }

  h4 {
    ${(props) => (props.uc ? "text-transform: uppercase;" : "")}
  }
`

const ImageContainer = styled.div`
  margin-bottom: 34px;
  max-width: 100px;
  max-height: 100px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  
`

const SimpleBlock = ({ content, image, bg = null, uppercaseH4 = null, padded = true, ...rest }) => {
  return (
    <BlockContainer bg={bg} padded={padded} {...rest}>
      <Content>
        {image && (
          <ImageContainer>
            <GatsbyImage
              image={image && image.gatsbyImageData}
              alt={image.description || process.env.WEBSITE_TITLE}
            />
          </ImageContainer>
        )}
        {content && (
          <Copy small={image} bg={bg} uc={uppercaseH4}>
            {renderRichText(content)}
          </Copy>
        )}
      </Content>
    </BlockContainer>
  )
}

export default SimpleBlock
