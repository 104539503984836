import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import colors from "src/styles/colors"
import fonts from "src/styles/fonts"
import breakpoints from "src/styles/breakpoints"
import Block from "./Block"
import CtaButton from "../../Core/CtaButton"

const BlockContainer = styled.div`
  background: ${(props) =>
    props.bg === "Blue"
      ? colors.royalBlue
      : props.bg === "Pink"
      ? colors.pink
      : colors.lightGrey};
  padding: 6.6vmax 6vw;
  text-align: center;
`

const Content = styled.div`
  max-width: 1200px;
  margin: auto;
  h2 {
    font-size: 2rem;
    color: ${colors.yellow};
    text-transform: uppercase;
    max-width: 600px;
    margin: auto;
    margin-bottom: 17px;
    line-height: 1.1;
  }
  @media (min-width: ${breakpoints.md}) {
    width: 75%;
    margin: auto;
  }

  @media (min-width: ${breakpoints.lg}) {
    h2 {
      font-size: calc(2.4vw + 1rem);
      font-weight: 900;
      @media (min-width: 1600px){
    font-size: 55px;
  }
    }
  }
`

const EyebrowIcons = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 17px;
`

const Icon = styled.div`
  margin: 0 6px;
`

const ButtonContainer = styled.div`
  margin-top: 34px;
`

const PageBlockGroup = ({
  title,
  backgroundColor,
  blocks,
  eyebrowIcons,
  ctaLabel,
  ctaLink,
}) => {
  return (
    <BlockContainer bg={backgroundColor}>
      <Content>
        {eyebrowIcons && eyebrowIcons.length > 0 && (
          <EyebrowIcons>
            {eyebrowIcons.map((icon, index) => {
              return (
                <Icon key={index}>
                  <GatsbyImage
                    key={index}
                    image={icon.gatsbyImageData}
                    alt={icon.description || title}
                  />
                </Icon>
              )
            })}
          </EyebrowIcons>
        )}
        {title && <h2 dangerouslySetInnerHTML={{ __html: title }} />}
        {blocks && blocks.map((block, index) => {
          const isEven = index % 2 === 0
          return <Block key={index} {...block} isEven={isEven} />
        })}

        {ctaLabel && ctaLink && (
          <ButtonContainer>
            <CtaButton label={ctaLabel} url={ctaLink} />
          </ButtonContainer>
        )}
      </Content>
    </BlockContainer>
  )
}

export default PageBlockGroup
