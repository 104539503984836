import React from "react"
import styled from "styled-components"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { GatsbyImage } from "gatsby-plugin-image"
import CtaButton from "../Core/CtaButton"
import colors from "src/styles/colors"
import fonts from "src/styles/fonts"
import breakpoints from "src/styles/breakpoints"
import {
  MOBILE_HEADER_HEIGHT,
  DESKTOP_HEADER_HEIGHT,
} from "src/components/Layout/Layout"

const HeroBlock = styled.div`
  position: relative;
  max-width: 100vw;
  overflow: hidden;
`

const StyledImage = styled(GatsbyImage)`
  height: 88vh;
  min-height: 500px;
  width: 100vw;
`

const HeroContentContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 6vw;
  text-align: center;
  color: ${colors.white};
  flex-wrap: wrap;
  padding-top: ${MOBILE_HEADER_HEIGHT}px;
  max-width: 1600px;

  @media (min-width: ${breakpoints.md}) {
    padding-top: ${DESKTOP_HEADER_HEIGHT}px;
  }
`

const HeroContent = styled.div`
  h1 {
    font-size: 2.5rem;
  }
  @media (min-width: ${breakpoints.md}) {
    width: 65%;

    h1 {
      font-size: 3.5em;
    }
  }
`

const ButtonContainer = styled.div`
  margin-top: 34px;
`

const Hero = ({ heroData }) => {
  const { ctaLabel, ctaUrl, image, title } = heroData

  return (
    <HeroBlock>
      <StyledImage
        image={image && image.gatsbyImageData}
        alt={(image && image.description) ? image.description : title}
      />
      <HeroContentContainer>
        <HeroContent>
          <div>
            {title && <h1>{title}</h1>}
            {ctaLabel && ctaUrl && (
              <ButtonContainer>
                <CtaButton url={ctaUrl} label={ctaLabel} />
              </ButtonContainer>
            )}
          </div>
        </HeroContent>
      </HeroContentContainer>
    </HeroBlock>
  )
}

export default Hero
