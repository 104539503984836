import React from "react"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import colors from "src/styles/colors"
import fonts from "src/styles/fonts"
import breakpoints from "src/styles/breakpoints"
import { Col, Row } from "styled-bootstrap-grid"

const SingleBlock = styled.div`
  text-align: left;
  padding: 17px 0;

  @media (min-width: ${breakpoints.md}) {
    padding: 52px 0;
  }
`

const ImageContainer = styled.div`
  position: relative;
  z-index: 0;
`

const Heading = styled.h3`
  color: ${colors.white};
  display: inline-block;
  line-height: 1.5;
  font-size: 2rem;
  margin-top: calc(-1em - 1.5%);
  position: relative;
  z-index: 1;
  padding: 0 4%;
  span {
    background: ${(props) =>
      props.highlight === "Blue"
        ? colors.royalBlue
        : props.highlight === "Yellow"
        ? colors.yellow
        : "transparent"};
    webkit-box-decoration-break: clone;
    box-decoration-break: clone;
    padding: 0 3%;
  }

  @media (min-width: ${breakpoints.md}) {
    margin-top: auto;
    margin-bottom: auto;
    padding: 0;
    font-size: calc(2.4vw + 1rem);
    width: 200%;
    font-weight: 900;
    @media (min-width: 1600px){
    font-size: 55px;
  }
    ${(props) =>
      !props.isEven
        ? "transform: translateX(0)"
        : "transform: translateX(-66%)"};
  }
`

const Text = styled.div`
  margin-top: 34px;
  color: ${colors.lightGrey};

  p {
    margin-bottom: 1rem;
    :last-of-type {
      margin-bottom: 0;
    }
  }

  b {
    ${fonts.workSansBold};
  }
`

const StyledRow = styled(Row)`
  position: relative;
`

const StyledImage = styled(GatsbyImage)`
  width: 100%;
`

const Block = (props) => {
  const { heading, media, content, isEven, headingHighlight } = props
  return (
    <SingleBlock>
      <StyledRow alignItems="center">
        {media && (
          <Col md={9} mdOrder={isEven ? "first" : "last"}>
            <ImageContainer>
              <StyledImage
                image={media.gatsbyImageData}
                alt={media.description || heading}
              />
            </ImageContainer>
          </Col>
        )}
        <Col md={3}>
          {heading && (
            <Heading highlight={headingHighlight} isEven={isEven}>
              <span>{heading}</span>
            </Heading>
          )}
        </Col>
      </StyledRow>
      {content && (
        <Row>
          <Col md={isEven ? 7 : 9} mdOffset={isEven ? 5 : 3}>
            <Text>{renderRichText(content)}</Text>
          </Col>
        </Row>
      )}
    </SingleBlock>
  )
}

export default Block
