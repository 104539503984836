import React from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Core/Seo"
import { graphql } from "gatsby"
import Hero from "../components/Index/Hero"
import Blocks from "../components/Index/Blocks"

const IndexPage = (props) => {
  const { location, data } = props
  const path = location.pathname

  const page = data.contentfulPage

  let sharingImage = false
  if (page.socialMediaImage) {
    sharingImage = page.socialMediaImage.file.url
  }

  return (
    <Layout>
      <Seo
        title={page.metaTitle}
        description={page.metaDescription}
        fullTitle={true}
        path={path}
        image={sharingImage}
      />

      <Hero heroData={page.hero} />
      <Blocks blocks={page.blocks} />
    </Layout>
  )
}

export default IndexPage 

export const pageQuery = graphql`
  query IndexQuery {
    contentfulPage(slug: { eq: "homepage" }) {
      ...pageFragment
    }
  }
`
